<template>
    <div class="platform">
        <dev-comp></dev-comp>
    </div>
</template>
<script>
    import devComp from "@/components/devDoc/dev-page.vue"
    export default {
        metaInfo: {
            title: "北京猎户星空科技有限公司",
            meta: [
                {
                    name: "dev",
                    content:
                        "猎户开发者文档中心，猎户开发者平台，OrionBase，机器人开发平台，机器人开发者平台，机器人开放平台，猎户开放平台，机器人开放平台"
                },
                {
                    name: "description",
                    content: "平台为开发者提供了简单高效的开发框架和功能强大的组件库，帮助开发者在机器人设备上开发满足业务场景需要的应用，大大降低了开发者的开发难度，提升开发效率"
                }
            ]
        },
        name: 'dev',
        components: {
            devComp
        }
    }
</script>
